/*global $ */
document.addEventListener("turbolinks:load", function () {
// $('#chk-submit-yes').on('keypress', e => {
//   if (e.keyCode == 13) {
//       return false;
//   }
// });

$(function(){
  
  //実施前か実施後をチェックした時のアクション
  $('.radio_btn_q_type').click(function(){
    $('.popup').addClass('is-show');
    let ba = $('input[name="form2[q_type]"]:checked').val();
    if (ba === "before"){
      $('#chk-warn-before').css('display','block');
    }else{
      $('#chk-warn-after').css('display','block');
    }
  });
  
  //popup 閉じるをクリックした時のアクション
  $('#js-close-btn').click(function(){
    $('.popup').removeClass('is-show');
    $('#chk-warn-before').css('display','none');
    $('#chk-warn-after').css('display','none');
  });
  
  //popup2 閉じるをクリックした時のアクション
  $('#js-close-btn2').click(function(){
    $('.popup2').removeClass('is-show');
  });
  
  //背景の黒いところをクリックした時のアクション
  $('#js-black-bg').click(function(){
    $('.popup').removeClass('is-show');
    $('#chk-warn-before').css('display','none');
    $('#chk-warn-after').css('display','none');
  });
  
  //背景の黒いところをクリックした時のアクション
  $('#js-black-bg2').click(function(){
    $('.popup2').removeClass('is-show');
  });
  
  //popup 「いいえ」をクリックした時のアクション
  $('#chk-warn-no').click(function(){
    $('.popup').removeClass('is-show');
    $('#chk-warn-before').css('display','none');
    $('#chk-warn-after').css('display','none');
  });
  
  //popup2 「いいえ」をクリックした時のアクション
  $('#chk-submit-no').click(function(){
    $('.popup2').removeClass('is-show');
  });
  
  //popup 「はい」をクリックした時のアクション
  $('#chk-warn-yes').click(function(){
    $('.popup').removeClass('is-show');
    $('.before_next_btn').css('display','block');
    $('#select-before-after').css('display','none');
    $('.form_top').css('display', 'none');
  });
  
  //popup2 「はい」をクリックした時のアクション
  // $('#chk-submit-yes').click(function(){
  //   $('.popup2').removeClass('is-show');
  //   $('.form-finish').css('display','block');
  //   $('.form-before').css('display','none');
  // });
  // next btnをクリックした時のアクション
  $('.next_btn').click(function(){
    
    let chk1 = $('input[name="form2[q_type]"]:checked').val();
    let chk2 = $('input[name="form2[school_name]"]').val().length > 0;
    let chk3 = $('input[name="form2[form_school_type]"]:checked').val();
    let chk4 = $('input[name="form2[year]"]:checked').val();
    let chk5 = $('input[name="form2[class_number]"]:checked').val();
    let chk6 = $('input[name="form2[id_number]"]').val().length > 0;
    let false_chk = 0;
    let scroll_pos = 0;
    
    
    
    if (chk6 == false){
      $('.chk6-alert').text('*入力してください');
      false_chk = 6;
      scroll_pos = $('.chk6-alert').offset().top;
    }else{
      $('.chk6-alert').text('');
    }
    if (typeof chk5 == "undefined"){
      $('.chk5-alert').text('*選択してください');
      false_chk = 5;
      scroll_pos = $('.chk5-alert').offset().top;
    }else{
      $('.chk5-alert').text('');
    }
    if (typeof chk4 == "undefined"){
      $('.chk4-alert').text('*選択してください');
      false_chk = 4;
      scroll_pos = $('.chk4-alert').offset().top;
    }else{
      $('.chk4-alert').text('');
    }
    if (typeof chk3 == "undefined"){
      $('.chk3-alert').text('*選択してください');
      false_chk = 3;
      scroll_pos = $('.chk3-alert').offset().top;
    }else{
      $('.chk3-alert').text('');
    }
    if (chk2 == false){
      $('.chk2-alert').text('*入力してください');
      false_chk = 2;
      scroll_pos = $('.chk2-alert').offset().top;
    }else{
      $('.chk2-alert').text('');
    }
    // if ((chk1 !== "before") && (chk1 !== "after")){
    if (typeof chk1 == "undefined") {
      $('.chk1-alert').text('*選択してください');
      false_chk = 1;
      scroll_pos = $('.chk1-alert').offset().top;
    }else{
      $('.chk1-alert').text('');
    }
    if (false_chk !== 0){
      //next btnをクリックした時に未入力の項目がある場合
      //topまでスクロールする
      $('html, body').animate({scrollTop:scroll_pos-140});
      
    }else{
      //next btnをクリックした時に未入力の項目がない場合
      //next btnのあと非表示にする
      $('.before_next_btn').css('display','none');
      $('.next_btn').css('display','none');
      
      //topまでスクロールする
      $('html, body').animate({scrollTop:$('.form_top').offset().top});
      
      
      // 実施前アンケートか実施後かで表示する内容を変更する
      if ($('input[name="form2[q_type]"]:checked').val() === 'before') {
        $('.before_class').css('display','block');
      } else {
        $('.after_class').css('display','block');
      }
      
      // 小学生か中学生で表示する内容を変更する
      if ($('input[name="form2[form_school_type]"]:checked').val() === 'element') {
        $('.q_ele').css('display','block');
      } else {
        $('.q_mid').css('display','block');
      }
      //submit btnを表示
        $('.submit-before-pannel').css('display','block');
    }
  });  
  
  // submit btnをクリックした時のアクション
  $('.submit-before-btn').click(function(){

    
    let ans_val = '';
    let ans_chk = 0;
    let scroll_val = 0;
    // 事前アンケートの場合
    if ($('input[name="form2[q_type]"]:checked').val() === 'before'){
      let ans1 = $('input[name="form2[Q1_b]"]:checked').val();
      let ans2 = $('input[name="form2[Q2_b]"]:checked').val();
      let ans3 = $('input[name="form2[Q3_b]"]:checked').val();
      let ans4 = $('input[name="form2[Q4_b]"]:checked').val();
      let ans5 = $('input[name="form2[Q5_b]"]:checked').val();
      let ans6 = $('input[name="form2[Q6_b]"]:checked').val();
      let ans7 = $('input[name="form2[Q7_b]"]:checked').val();
      let ans8 = $('input[name="form2[Q8_b]"]:checked').val();
      let ans9 = $('input[name="form2[Q9_b]"]:checked').val();
      let ans10 = $('input[name="form2[Q10_b]"]:checked').val();
      let ans11 = $('input[name="form2[Q11_b]"]:checked').val();
      ans_val = ans1 + ans2 + ans3 + ans4 + ans5 + ans6 + ans7 + ans8 + ans9 + ans10 + ans11;
      // 中学生だけ13問目の質問がある
      if ($('input[name="form2[form_school_type]"]:checked').val() === 'middle') {
      let ans12 = $('input[name="form2[Q12_b]"]:checked').val();
      let ans13 = $('input[name="form2[Q13_b]"]:checked').val();
      ans_val = ans_val + ans12 + ans13;
      
      
      if (typeof ans13 == "undefined") {
        $('.ans13-alert').text('*選択してください');
        ans_chk = 13;
        scroll_val = $('.ans13-alert').offset().top;
      }else{
        $('.ans13-alert').text('');
      }
      if (typeof ans12 == "undefined") {
        $('.ans12-alert').text('*選択してください');
        ans_chk = 12;
        scroll_val = $('.ans12-alert').offset().top;
      }else{
        $('.ans12-alert').text('');
      }
      }
      
      if (typeof ans11 == "undefined") {
        $('.ans11-alert').text('*選択してください');
        ans_chk = 11;
        scroll_val = $('.ans11-alert').offset().top;
      }else{
        $('.ans11-alert').text('');
      }
      if (typeof ans10 == "undefined") {
        $('.ans10-alert').text('*選択してください');
        ans_chk = 10;
        scroll_val = $('.ans10-alert').offset().top;
      }else{
        $('.ans10-alert').text('');
      }
      if (typeof ans9 == "undefined") {
        $('.ans9-alert').text('*選択してください');
        ans_chk = 9;
        scroll_val = $('.ans9-alert').offset().top;
      }else{
        $('.ans9-alert').text('');
      }
      if (typeof ans8 == "undefined") {
        $('.ans8-alert').text('*選択してください');
        ans_chk = 8;
        scroll_val = $('.ans8-alert').offset().top;
      }else{
        $('.ans8-alert').text('');
      }
      if (typeof ans7 == "undefined") {
        $('.ans7-alert').text('*選択してください');
        ans_chk = 7;
        scroll_val = $('.ans7-alert').offset().top;
      }else{
        $('.ans7-alert').text('');
      }
      if (typeof ans6 == "undefined") {
        $('.ans6-alert').text('*選択してください');
        ans_chk = 6;
        scroll_val = $('.ans6-alert').offset().top;
      }else{
        $('.ans6-alert').text('');
      }
      if (typeof ans5 == "undefined") {
        $('.ans5-alert').text('*選択してください');
        ans_chk = 5;
        scroll_val = $('.ans5-alert').offset().top;
      }else{
        $('.ans5-alert').text('');
      }
      if (typeof ans4 == "undefined") {
        $('.ans4-alert').text('*選択してください');
        ans_chk = 4;
        scroll_val = $('.ans4-alert').offset().top;
      }else{
        $('.ans4-alert').text('');
      }
      if (typeof ans3 == "undefined") {
        $('.ans3-alert').text('*選択してください');
        ans_chk = 3;
        scroll_val = $('.ans3-alert').offset().top;
      }else{
        $('.ans3-alert').text('');
      }
      if (typeof ans2 == "undefined") {
        $('.ans2-alert').text('*選択してください');
        ans_chk = 2;
        scroll_val = $('.ans2-alert').offset().top;
      }else{
        $('.ans2-alert').text('');
      }
      if (typeof ans1 == "undefined") {
        $('.ans1-alert').text('*選択してください');
        ans_chk = 1;
        scroll_val = $('.ans1-alert').offset().top;
      }else{
        $('.ans1-alert').text('');
      }
      
      if (ans_chk !== 0){
      //submiy btnをクリックした時に未入力の項目がある場合
      $('html, body').animate({scrollTop:scroll_val-140});
        return false;
      }else{
        $('.popup2').addClass('is-show');//popup2をだす
        // $('html, body').animate({scrollTop:0});
        // $('.form-finish').css('display','block');
        // $('.form-before').css('display','none');
      }
      
    } else {
      // 事後アンケートの場合
      let ans1 = $('input[name="form2[Q1_a]"]:checked').val();
      let ans2 = $('input[name="form2[Q2_a]"]:checked').val();
      let ans3 = $('input[name="form2[Q3_a]"]:checked').val();
      let ans4 = $('input[name="form2[Q4_a]"]:checked').val();
      let ans5 = $('input[name="form2[Q5_a]"]:checked').val();
      let ans6 = $('input[name="form2[Q6_a]"]:checked').val();
      let ans7 = $('input[name="form2[Q7_a]"]:checked').val();
      ans_val = ans1 + ans2 + ans3 + ans4 + ans5+ ans6 + ans7;
      // 中学生だけ10問目の質問がある
      if ($('input[name="form2[form_school_type]"]:checked').val() === 'middle') {
        let ans8 = $('input[name="form2[Q8_am]"]:checked').val();
        let ans9 = $('input[name="form2[Q9_am]"]:checked').val();
        let ans10 = $('input[name="form2[Q10_am]"]:checked').val();
        ans_val = ans_val + ans8 + ans9 + ans10;
        
        if (typeof ans10 == "undefined") {
          $('.ans10a-alert-mid').text('*選択してください');
          ans_chk = 10;
          scroll_val = $('.ans10a-alert-mid').offset().top;
        }else{
          $('.ans10a-alert-mid').text('');
        }
        if (typeof ans9 == "undefined") {
          $('.ans9a-alert-mid').text('*選択してください');
          ans_chk = 9;
          scroll_val = $('.ans9a-alert-mid').offset().top;
        }else{
          $('.ans9a-alert-mid').text('');
        }
        if (typeof ans8 == "undefined") {
          $('.ans8a-alert-mid').text('*選択してください');
          ans_chk = 8;
          scroll_val = $('.ans8a-alert-mid').offset().top;
        }else{
          $('.ans8a-alert-mid').text('');
        }
      } else {
        //middleじゃないとき
        let ans8 = $('input[name="form2[Q8_ae]"]:checked').val();
        let ans9 = $('input[name="form2[Q9_ae]"]:checked').val();
        ans_val = ans_val + ans8 + ans9;
        
        if (typeof ans9 == "undefined") {
          $('.ans9a-alert-ele').text('*選択してください');
          ans_chk = 9;
          scroll_val = $('.ans9a-alert-ele').offset().top;
        }else{
          $('.ans9a-alert-ele').text('');
        }
        if (typeof ans8 == "undefined") {
          $('.ans8a-alert-ele').text('*選択してください');
          ans_chk = 8;
          scroll_val = $('.ans8a-alert-ele').offset().top;
        }else{
          $('.ans8a-alert-ele').text('');
        }
      }
      
      
      if (typeof ans7 == "undefined") {
        $('.ans7a-alert').text('*選択してください');
        ans_chk = 7;
        scroll_val = $('.ans7a-alert').offset().top;
      }else{
        $('.ans7a-alert').text('');
      }
      if (typeof ans6 == "undefined") {
        $('.ans6a-alert').text('*選択してください');
        ans_chk = 6;
        scroll_val = $('.ans6a-alert').offset().top;
      }else{
        $('.ans6a-alert').text('');
      }
      if (typeof ans5 == "undefined") {
        $('.ans5a-alert').text('*選択してください');
        ans_chk = 5;
        scroll_val = $('.ans5a-alert').offset().top;
      }else{
        $('.ans5a-alert').text('');
      }
      if (typeof ans4 == "undefined") {
        $('.ans4a-alert').text('*選択してください');
        ans_chk = 4;
        scroll_val = $('.ans4a-alert').offset().top;
      }else{
        $('.ans4a-alert').text('');
      }
      if (typeof ans3 == "undefined") {
        $('.ans3a-alert').text('*選択してください');
        ans_chk = 3;
        scroll_val = $('.ans3a-alert').offset().top;
      }else{
        $('.ans3a-alert').text('');
      }
      if (typeof ans2 == "undefined") {
        $('.ans2a-alert').text('*選択してください');
        ans_chk = 2;
        scroll_val = $('.ans2a-alert').offset().top;
      }else{
        $('.ans2a-alert').text('');
      }
      if (typeof ans1 == "undefined") {
        $('.ans1a-alert').text('*選択してください');
        ans_chk = 1;
        scroll_val = $('.ans1a-alert').offset().top;
      }else{
        $('.ans1a-alert').text('');
      }
      if (ans_chk !== 0){
      //submiy btnをクリックした時に未入力の項目がある場合
      $('html, body').animate({scrollTop:scroll_val-140});
        return false;
      }else{
        $('.popup2').addClass('is-show');//popup2をだす
      }
    }
    $('.ans-cnt').val(ans_val);
  });
  
  
});
});