/*global $ */
document.addEventListener("turbolinks:load", function () {
$(function(){
  let cnt = 0;
  const loadTime = 2500;//2500
  const scrollTime = 1500;//1500
  
  let clicked_max = 0;//clickされた最大値を保存しておく変数
  
  let a1 = 0;
  let a2 = 0;
  let a3 = 0;
  let a4 = 0;
  let a5 = 0;
  let a6 = 0;
  let a7 = 0;
  let a8 = 0;
  let a9 = 0;
  
  // ai-btnがclickされたとき
  $('.ai-btn').click(function(){
    // 要素を生成して追加する
    var newQuestion = $('ai-form');
    $('#parentDiv').append(newQuestion);
    
    // clickされたq-numberを取得する
    let clicked_q = $(this).attr('class').split(" ")[1];
    let clicked_q_num = Number(clicked_q.charAt(clicked_q.length - 1)); // 最後の文字列
    let next_pos = 0;
    
    switch (clicked_q_num) {
      case 1:
        a1 = $(this).val();
        $(this).siblings().removeClass('clicked_btn');
        $(this).addClass('clicked_btn');
        break;
      case 2:
        a2 = $(this).val();
        $(this).siblings().removeClass('clicked_btn');
        $(this).addClass('clicked_btn');
        break;
      case 3:
        a3 = $(this).val();
        $(this).siblings().removeClass('clicked_btn');
        $(this).addClass('clicked_btn');
        break;
      case 4:
        a4 = $(this).val();
        $(this).siblings().removeClass('clicked_btn');
        $(this).addClass('clicked_btn');
        break;
      case 5:
        a5 = $(this).val();
        $(this).siblings().removeClass('clicked_btn');
        $(this).addClass('clicked_btn');
        break;
      case 6:
        a6 = $(this).val();
        $(this).siblings().removeClass('clicked_btn');
        $(this).addClass('clicked_btn');
        break;
      case 7:
        a7 = $(this).val();
        $(this).siblings().removeClass('clicked_btn');
        $(this).addClass('clicked_btn');
        break;
      case 8:
        a8 = $(this).val();
        $(this).siblings().removeClass('clicked_btn');
        $(this).addClass('clicked_btn');
        break;
      case 9:
        a9 = $(this).val();
        $(this).siblings().removeClass('clicked_btn');
        $(this).addClass('clicked_btn');
        break;
    }
    
    // 未回答の質問が回答された時
    if (clicked_max < clicked_q_num){
      
      clicked_max = clicked_q_num;

      if (clicked_max == ($('.ai-form').length)){
        //最後の質問のとき
        $('.ai-submit-pannel').fadeIn(1000);
        next_pos = $('.ai-submit').offset().top;
        $('html, body').animate({scrollTop:next_pos},scrollTime);
      }else{
        //最後の質問じゃない時
        let $displayQuestion = $('.active');//オブジェクト変数
      
        let $load = $('.n-load');
        $load.addClass('loader');
        let loader_pos = $('.loader').offset().top;
        $('html, body').animate({scrollTop:loader_pos},scrollTime);
        $load.nextAll('.load:first').addClass('n-load');
        //三秒遅らせて実行
        setTimeout(function(){
          $load.removeClass('loader');
          $load.removeClass('n-load');
          
          $displayQuestion.nextAll('.ai-form:first').addClass('active');
          $displayQuestion.removeClass('active');
          $displayQuestion.addClass('clicked');
          next_pos = $displayQuestion.next().offset().top;
          
          //次の質問までスクロールする
          $('html, body').animate({scrollTop:next_pos},scrollTime);
        },loadTime);
      }
    } 
    
    cnt = a9 + a8*10 + a7*100 + a6*1000 + a5*10000 + a4*100000 + a3*1000000 + a2*10000000 + a1*100000000;
    $('.diag-cnt').val(cnt);
    
  });
  

});

// postされた後
$(window).on('load',function(){
    setTimeout(function(){
    $('#result-load').removeClass('loader');
    $('.ai-result').fadeIn(1000, function(){
      setTimeout(function(){
        $('.ai-message2').fadeIn(1000);
      },1000);
      setTimeout(function(){
        $('.result_1').fadeIn(1000);
      },2000);
      setTimeout(function(){
        $('.result_2').fadeIn(1000);
      },3000);
      setTimeout(function(){
        $('.result_3').fadeIn(1000);
      },4000);
    });
  },5000);
});
});